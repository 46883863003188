textarea {
  resize: vertical;
  width: 100%;
  border: 1px solid black;
}

.questionCard {
  width: 40%;
  box-sizing: border-box;
  height: calc(100vh - 10vh);
  background-color: white;
  border-radius: 20px;
  overflow-y: auto;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
    rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
    rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
}

.questionCard::-webkit-scrollbar {
  display: none;
}

/* change here */

.button {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  padding: 10px 20px 10px 20px;
  background-color: rgb(233, 29, 37);
  color: white;
  outline: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 700;
  font-size: 12px;
}

.button:active {
  background-color: rgb(218, 96, 100);
  transform: translate(2px, 2px);
}

.button:disabled {
  background-color: rgba(216, 125, 128, 0.856);
}

.question-text {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
}

.question-body {
  display: flex;
  flex-direction: column;
  height: calc(100% - 50px);
  width: 100%;
  padding: 30px;
  padding-bottom: 0px;
  gap: 20px;
  overflow-y: auto;
  background-color: #ebeaea !important;
}

::-webkit-scrollbar {
  width: 5px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media screen and (max-width: 1500px) {
  .button {
    font-size: 10px;
  }
}

@media screen and (max-width: 1100px) {
  .button {
    padding: 10px 10px 10px 10px;
  }
}

@media screen and (max-width: 1000px) {
  .button {
    padding: 10px 20px 10px 20px;
  }
}

@media screen and (max-width: 700px) {
  .question-text {
    font-size: 16px;
    font-weight: 500;
  }
}

@media screen and (max-width: 1000px) {
  textarea {
    width: 100%;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 600px) {
  textarea {
    max-height: auto;
  }
}

@media screen and (max-width: 900px) {
  .questionCard {
    width: 60%;
  }
}

@media screen and (max-width: 700px) {
  .questionCard {
    width: 100%;
  }
}

@media screen and (max-width: 700px) {
  .question-body {
    height: 92%;
  }
}

/* change here */
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: rgb(233, 29, 37) !important;
}
